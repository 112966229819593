import React from 'react';
import { DescriptionText } from './styles';
const ServiceDescription = ({
  service
}) => {
  return <DescriptionText>
      {service === '0' && 'Includes search for and retrieval/collection of documents from an official source, such as a registry or judicial court. If documents are not in English, a written summary of the key points of the will be included along with the document copies. Document retrievals may be conducted online or in-person, as appropriate. Additional fees may apply if verbatim translations or certified or notarised copies are required.'}
      {service === '1' && <>
          Includes a visit to the site provided in the site description along
          with up to five photos of the site to provide confirmation of key
          details, such as its existence and the presence or absence of a
          specific residence or business at the site. Site visits will be
          conducted in person and not virtually (e.g. via Google Street View),
          unless otherwise agreed.
          <br /> <br />
          Partners will conduct themselves discreetly but in accordance with all
          applicable laws and regulations, as well as with site-specific rules.
          Partners will not attempt to gain unauthorised access to restricted
          areas, and will comply with all instructions from authorised personnel
          at the site. For example, if photography is prohibited, the partner
          will not attempt to take photos, and will include an explanation in
          their report. Partners will not attempt to complete any site visit
          that poses a threat to their safety.
        </>}
      {service === '2' && 'Includes open-source research, such as public records and media checks, as well as human source enquiries (if required) on individuals and/or entities for compliance-driven requirements. The extent of the research will depend on the type of report selected. Please click the below button to see detailed descriptions of the items included in the scope for each report type.'}
      {service === '3' && 'Includes discreet enquiries with human sources who are knowledgeable about a specific individual, entity, or other topic. The number of enquiries can be set according to your needs. Only enquiries with sources that are able to provide substantive comment will be counted towards the total number of enquiries'}
      {service === '9' && 'Please select this option if your request does not fit into one of the above categories or requires additional discussion before proceeding.'}
    </DescriptionText>;
};
export default ServiceDescription;