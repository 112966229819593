import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc4;
  z-index: 1000;
  top: 0;
  left: 0;
  margin-top: ${({
  hideHeader
}) => hideHeader ? '0px' : '64px'};
`;
export const UploadStatusText = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #d3d3d3;
`;
export const Message = styled.div`
  margin-top: 10px;
  color: #d3d3d3;
  font-size: 14px;
`;