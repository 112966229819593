import { lighten, shade } from 'polished';
import styled, { css } from 'styled-components';
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  p {
    ${({
  backgroundColor
}) => css`
      color: ${shade(0.1, backgroundColor)};
    `}
    font-size: 15px;
    margin: 4px 0;
    padding: 4px 8px;
    margin-bottom: 4px;
    border-radius: 8px;
    ${({
  backgroundColor
}) => css`
      background-color: ${lighten(0.4, backgroundColor)};
    `}

    svg {
      color: #000000;
      font-size: 14px;
      margin-left: 8px;
      ${({
  backgroundColor
}) => css`
        color: ${shade(0.2, backgroundColor)};
      `}
    }
  }
  svg {
    color: #000000;
    font-size: 18px;
  }
`;