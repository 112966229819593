import styled, { css } from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  & + & {
    margin-left: 8px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const Text = styled.span`
  font-weight: 400;
  ${props => props.bold && css`
      font-weight: bold;
    `}

  ${props => props.color && css`
      color: ${props.color};
    `}
`;
export const ActivatedContainer = styled(Column)`
  width: 120px;
  align-items: center;

  @media (max-width: 650px) {
    align-items: flex-start;
    width: auto;
  }
`;
export const Unread = styled.div`
  background: #f00;
  color: #fff;
  width: 18px;
  height: 18px;
  font-size: 10px;
  border-radius: 50%;
  text-align: center;
  padding: 1px;
  display: inline-block;
  margin-left: 5px;
`;