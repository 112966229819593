import React from 'react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Input } from 'antd';
const COL_XS = 24;
const COL_LG = 12;
const FormInput = ({
  label,
  name,
  required,
  type,
  placeholder,
  value,
  handleFormState,
  isNotAutoComplete,
  testid
}) => {
  return <Col xs={COL_XS} lg={COL_LG} data-testid="form-input">
      <Form.Item label={<>
            {label}
            {required && <FontAwesomeIcon icon={faAsterisk} className="required-asterisk" />}
          </>} name={name}>
        <Input autoComplete={isNotAutoComplete && 'off'} data-lpignore={isNotAutoComplete && 'true'} onChange={input => handleFormState && handleFormState(name, input.target.value)} placeholder={placeholder ?? label} defaultValue={value} type={type} required={required} data-testid={testid ? testid : name} />
      </Form.Item>
    </Col>;
};
export default FormInput;