import React from 'react';
function Index({
  name,
  size = '40',
  style = {},
  bgTitle = ''
}) {
  return <div style={{
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: `${bgTitle}`,
    lineHeight: `${size}px`,
    textAlign: 'center',
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
    ...style
  }}>
      {name}
    </div>;
}
export default Index;