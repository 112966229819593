import React, { useEffect, useState } from 'react';
import { FallbackImage, Image } from './styles';
const ImageWithFallbackOnError = ({
  imageSrc,
  fallback,
  alt,
  className
}) => {
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    setImageError(false);
  }, [imageSrc]);
  if (imageError) return <FallbackImage className={className}>{fallback}</FallbackImage>;
  return <Image className={className} src={imageSrc} onError={e => {
    e.target.onerror = null;
    setImageError(true);
  }} alt={alt ?? 'Image'} data-testid="image-with-fallback-error" />;
};
export default ImageWithFallbackOnError;