import React, { useCallback } from 'react';
import { Tabs as TabsContainer } from 'antd';
import { Container } from './styles';
const Tabs = ({
  type,
  responsiveWidth = 800,
  tabPosition = 'left',
  onChange,
  defaultActiveKey,
  children
}) => {
  const handleOnChange = useCallback(key => {
    onChange && onChange(key);
  }, [onChange]);
  return <Container responsiveWidth={responsiveWidth}>
      <TabsContainer type={type} tabPosition={tabPosition} onChange={handleOnChange} defaultActiveKey={defaultActiveKey} data-testid="tabs">
        {children}
      </TabsContainer>
    </Container>;
};
export default Tabs;
export { Container as TabsContainer };