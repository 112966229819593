import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Container } from './styles';
const Option = ({
  label,
  backgroundColor,
  isSelected,
  Icon,
  onClick
}) => {
  return <Container onClick={event => {
    onClick && onClick(event);
  }} backgroundColor={backgroundColor ?? '#ffffff'} data-testid="option">
      <p>
        {label}
        {Icon ?? ''}
      </p>
      {isSelected && <CheckOutlined size={25} />}
    </Container>;
};
export default Option;