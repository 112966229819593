import React from 'react';
import { Container } from './styles';
const TabTitle = ({
  title,
  Icon,
  beta
}) => {
  return <Container data-testid="tab-title">
      {!!Icon && Icon}
      <div className="title">{title}</div>
      {beta && <div className="beta">Beta</div>}
    </Container>;
};
export default TabTitle;