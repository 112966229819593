import { Switch } from 'antd';
import React from 'react';
import { SwitchContainer } from './styles';
function SwitchButton({
  checked,
  onChange,
  title
}) {
  return <SwitchContainer>
      {title && <strong>{title}: &nbsp;</strong>}
      <Switch defaultChecked={checked} onChange={onChange} />
    </SwitchContainer>;
}
export default SwitchButton;