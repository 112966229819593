import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  width: 100%;

  .ant-tabs-tabpane {
    padding: 0 !important;
  }

  .ant-tabs-nav {
    min-width: 220px;
  }

  .ant-tabs-content {
    background-color: #ffffff;
  }

  div.ant-tabs-tab {
    background: none !important;
    border-radius: 0 !important;
    border: 0px !important;
    color: #778ca2;
    transition: all 0s 0s !important;
    width: 220px;

    .ant-tabs-tab-btn {
      transition: color 0s 0s !important;
    }

    @media (max-width: 580px) {
      width: auto;
    }

    svg {
      width: 14px;
      height: 14px;
    }

    &:active,
    &:hover {
      color: rgba(0, 0, 0, 0.85);

      path {
        fill: #ffcd29;
      }
    }
  }
  div.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffffff !important;
    border: 0px !important;
    border-bottom: 1px solid #ffba00 !important;

    .title {
      color: rgba(0, 0, 0, 0.85);
    }

    path {
      fill: #ffcd29;
    }
  }
  div.ant-tabs.ant-tabs-top.ant-tabs-card {
    background-color: #ffffff;
  }

  @media (max-width: ${({
  responsiveWidth
}) => responsiveWidth}px) {
    div.ant-tabs.ant-tabs-left.ant-tabs-card {
      flex-direction: column;
    }
    div.ant-tabs-nav-list {
      flex-direction: row !important;
    }
    div.ant-tabs-tabpane.ant-tabs-tabpane-active {
      padding: 0 8px !important;
    }
  }
`;