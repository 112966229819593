import styled from 'styled-components';
export const Icon = styled.div`
  margin-right: ${({
  page
}) => page ? '4px' : '8px'};
  margin-top: ${({
  page
}) => page ? '4px' : '6px'};
  .anticon {
    color: ${({
  checked,
  page
}) => checked ? '#ff9900' : page ? 'white' : 'lightgrey'} !important;
    -webkit-transition: color 100ms linear;
    -ms-transition: color 100ms linear;
    transition: color 100ms linear;
    font-size: 18px;
  }
`;