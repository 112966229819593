import React, { forwardRef, useCallback, useState, useImperativeHandle } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import debounce from 'lodash/debounce';
import { DEBOUNCE_WAIT_TIME } from '@gtintel/platform-constants';
import { Container, InputContainer } from './styles';
const SearchInput = ({
  className = '',
  placeholder = 'Search',
  styles,
  value,
  enableDebounce = false,
  clearOnBlur,
  changeSearchText,
  onFocus,
  onBlur
}, ref) => {
  const [text, setText] = useState('');
  useImperativeHandle(ref, () => ({
    reset: () => {
      setText('');
    }
  }), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeSearchText = useCallback(debounce(changeSearchText, DEBOUNCE_WAIT_TIME, {
    trailing: true
  }), [changeSearchText]);
  const handleChangeSearchText = useCallback(searchText => enableDebounce ? debouncedChangeSearchText(searchText) : changeSearchText(searchText), [enableDebounce, changeSearchText, debouncedChangeSearchText]);
  const handleChange = useCallback(newText => {
    setText(newText);
    handleChangeSearchText(newText);
  }, [handleChangeSearchText]);
  return <Container className={className}>
      <InputContainer style={styles}>
        <Input value={value ?? text} placeholder={placeholder} onChange={e => handleChange(e.currentTarget.value)} onFocus={onFocus} onBlur={() => {
        if (clearOnBlur) {
          setText('');
        }
        onBlur && onBlur();
      }} onReset={() => {
        setText('');
      }} />
        <SearchOutlined onClick={() => handleChangeSearchText(text)} />
      </InputContainer>
    </Container>;
};
export default forwardRef(SearchInput);