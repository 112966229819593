import React, { useEffect, useState } from 'react';
import { Image } from './styles';
const AvatarWithFallbackOnError = ({
  imageSrc,
  fallback,
  alt,
  className
}) => {
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    setImageError(false);
  }, [imageSrc]);
  if (imageError) return <Image className={className} src={fallback} alt={alt ?? 'Image'} />;
  return <Image className={className} src={imageSrc} onError={e => {
    e.target.onerror = null;
    setImageError(true);
  }} alt={alt ?? 'Image'} data-testid="avatar-with-fallback" />;
};
export default AvatarWithFallbackOnError;