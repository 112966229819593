import React from 'react';
import { ArrowRightIcon } from '@gtintel/platform-atoms';
import * as S from './styles';
const BackButton = ({
  onClick
}) => {
  return <S.Container onClick={onClick} data-testid="back-button">
      <ArrowRightIcon />
      Back
    </S.Container>;
};
export default BackButton;