import styled from 'styled-components';
export const Container = styled.div`
  --antd-wave-shadow-color: #98a9bc !important;

  .ant-radio-inner {
    background-color: ${({
  checked
}) => checked ? '#ffffff' : '#E8ECEF'};
    border: ${({
  checked
}) => checked ? '4px solid #98A9BC' : ' 1px solid #E8ECEF'};
  }
  .ant-radio-inner::after {
    top: 0;
    left: 0;
    background-color: ${({
  checked
}) => checked ? '#ffffff' : '#E8ECEF'};
  }
  .ant-radio-input:focus {
    border: ${({
  checked
}) => checked ? '4px solid #98A9BC' : ' 1px solid #E8ECEF'};
  }
  .ant-radio-input:focus + .ant-radio-inner {
    border: ${({
  checked
}) => checked ? '4px solid #98A9BC' : ' 1px solid #E8ECEF'};
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: #98a9bc;
  }
`;