import React from 'react';
import { Tag } from 'antd';
const SelectedTag = props => {
  const {
    tag,
    stateData: {
      setSelectedTags,
      selectedTags
    }
  } = props;
  const [name, id, color] = tag;
  return <Tag closable key={Number(id)} onClose={() => {
    setSelectedTags(selectedTags.filter(tag => tag[1] !== id));
  }} color={color.toString()} data-testid="selected-tag">
      {name}
    </Tag>;
};
export default SelectedTag;