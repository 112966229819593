import styled from 'styled-components';
export const Container = styled.div`
  height: 100%;
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  input {
    padding-right: 40px;
    height: 100%;
  }
  span {
    cursor: pointer;
    margin-top: -8px;
    margin-left: -34px;
    z-index: 2;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;