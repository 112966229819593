import styled from 'styled-components';
export const Image = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
`;
export const FallbackImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #b1b1b1;
  border-radius: 8px;

  svg {
    width: 28px;
    height: 28px;
    color: #ffffff;
  }
`;