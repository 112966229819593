import React from 'react';
import { LoadingSpinner2 } from '@gtintel/platform-atoms';
import { Container, Message, UploadStatusText } from './styles';
const AbsoluteLoading = ({
  isLoading,
  hideHeader,
  totalFiles,
  uploadedFiles,
  message
}) => isLoading ? <Container hideHeader={hideHeader ?? false} data-testid="absolute">
      <LoadingSpinner2 />
      {(totalFiles !== undefined || uploadedFiles !== undefined) && <UploadStatusText>
          {uploadedFiles + ' / ' + totalFiles}
        </UploadStatusText>}
      <Message>{message}</Message>
    </Container> : <></>;
export default AbsoluteLoading;