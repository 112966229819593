import React from 'react';
import { Radio } from 'antd';
import { Container } from './styles';
const RadioButton = ({
  name,
  checked,
  disabled,
  onChange,
  children
}) => {
  return <Container checked={checked} data-testid="radio-button">
      <Radio data-testid={`${name.toLowerCase().replace(/\s/g, '')}-radiobutton`} name={name} checked={checked} disabled={disabled} onChange={onChange}>
        {children}
      </Radio>
    </Container>;
};
export default RadioButton;