import React from 'react';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'antd';
import { TAG_COLORS } from '@gtintel/platform-constants';
function TagsGroupList({
  data
}) {
  return <List.Item key={data.id} style={{
    color: TAG_COLORS[Number(data.code)]
  }} color={TAG_COLORS[Number(data.code)]} className="ant-tag-user">
      <FontAwesomeIcon icon={faTag} size="lg" />
      &ensp;
      {data.name}
    </List.Item>;
}
export default TagsGroupList;