import React from 'react';
import { Spin } from 'antd';
const LoadingSpinner2 = ({
  margin = ''
}) => {
  const style = {
    textAlign: 'center',
    margin: String(margin)
  };
  return <div className="spin_loading" style={style}>
      <Spin tip="Loading..." size="large" />
    </div>;
};
export default LoadingSpinner2;