import styled from 'styled-components';
export const Header = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 14px 0;

  > span {
    color: #98a9bc;
    margin-left: 4px;
  }
`;
export const TagContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
`;
export const TagItem = styled.span`
  background: #f8fafb;
  border: 1px sold #e8ecef;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 8px;
  padding: 4px 8px;
  width: auto;
`;
export const SubjectTagLine = styled.div`
  color: #98a9bc;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 14px;

  span {
    :not(:first-child) {
      margin-left: 8px;
      &:before {
        content: '·';
        margin-right: 8px;
      }
    }
  }
`;
export const Address = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: [icon] 20px [text] auto;
  margin-bottom: 14px;

  > .la {
    align-self: center;
    color: #111 !important;
    font-size: 20px;
    grid-column-start: icon;
  }

  p {
    align-self: center;
    grid-column-start: text;
    margin-bottom: 0;
  }
`;
export const SubjectTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 8px;
`;
export const SubjectDescription = styled.div`
  margin: 14px 0;

  h4 {
    color: #98a9bc;
    font-weight: normal;
  }
`;