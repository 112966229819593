import React, { useEffect } from 'react';
import { Button } from 'baseui/button';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { getProjectDescription } from '@gtintel/platform-api';
import { DEFAULT_DATE_TIME_NOW } from '@gtintel/platform-constants';
import { useAsyncOp } from '@gtintel/platform-utils';
import { ScopeList } from '../ScopeCard';
import { SubjectList } from '../SubjectCard';
import * as S from './styles';
const ProjectServiceDescription = ({
  projectId,
  projectName,
  projectInstructionsHtml,
  scopes
}) => {
  const [subjects, setSubjects] = React.useState([]);
  const {
    error,
    isLoading,
    fn: getDescriptions
  } = useAsyncOp(getProjectDescription, {
    isLoading: true
  });
  useEffect(() => {
    getDescriptions({
      projectId: projectId
    }).then(res => {
      if (res && res.results.length > 0) {
        setSubjects(res.results);
      }
    });
  }, [projectId]);
  if (isLoading) {
    return <ClipLoader size={10} color="#777777" />;
  }
  if (error) {
    return <S.DescriptionContainer>
        <S.Header>Error loading project description</S.Header>
        <S.DescriptionText>
          If the problem persists, please raise an incident with the following
          information:
          <br />
          <pre>
            <code>
              Project ID: {projectId}
              <br />
              Project Name: {projectName}
              <br />
              Time: {moment().format(DEFAULT_DATE_TIME_NOW)}
            </code>
          </pre>
        </S.DescriptionText>
      </S.DescriptionContainer>;
  }
  return <S.DescriptionContainer>
      {scopes && scopes.length > 0 && <S.Title>{projectName}</S.Title>}

      {scopes && scopes.length > 0 && subjects && subjects.length > 0 && <SubjectList subjects={subjects} />}

      <S.Section>
        <S.Header>Additional Info</S.Header>

        {!projectInstructionsHtml || projectInstructionsHtml === '' ? subjects.length && subjects[0].service_type ? {
        0: 'Includes search for and retrieval/collection of documents from an official source, such as a registry or judicial court. If documents are not in English, a written summary of the key points of the will be included along with the document copies. Document retrievals may be conducted online or in-person, as appropriate. Additional fees may apply if verbatim translations or certified or notarised copies are required.',
        1: <>
                  Includes a visit to the site provided in the site description
                  along with up to five photos of the site to provide
                  confirmation of key details, such as its existence and the
                  presence or absence of a specific residence or business at the
                  site. Site visits will be conducted in person and not
                  virtually (e.g. via Google Street View), unless otherwise
                  agreed.
                  <br /> <br />
                  Partners will conduct themselves discreetly but in accordance
                  with all applicable laws and regulations, as well as with
                  site-specific rules. Partners will not attempt to gain
                  unauthorised access to restricted areas, and will comply with
                  all instructions from authorised personnel at the site. For
                  example, if photography is prohibited, the partner will not
                  attempt to take photos, and will include an explanation in
                  their report. Partners will not attempt to complete any site
                  visit that poses a threat to their safety.
                </>,
        2: <>
                  <div>
                    Includes open-source research, such as public records and
                    media checks, as well as human source enquiries (if
                    required) on individuals and/or entities for
                    compliance-driven requirements. The extent of the research
                    will depend on the type of report selected. Please see the
                    next page for detailed descriptions of the items included in
                    the scope for each report type.
                  </div>
                  <Button onClick={() => window.open(`${process.env.BASE_PATH}pdf/scope_new.pdf`).focus()} style={{
            marginTop: 20
          }}>
                    Due Dilligence Descriptions
                  </Button>
                </>,
        3: 'Includes discreet enquiries with human sources who are knowledgeable about a specific individual, entity, or other topic. The number of enquiries can be set according to your needs. Only enquiries with sources that are able to provide substantive comment will be counted towards the total number of enquiries',
        9: 'This project does not contain standardised scope requirements. Please refer to the project description provided on the Project Overview tab for the scope details. If needed, please use the chat functionality to ensure that all parties are clear on the requirements prior to project activation.'
      }[subjects[0].service_type] : 'This project does not contain standardised scope requirements. Please refer to the project description provided on the Project Overview tab for the scope details. If needed, please use the chat functionality to ensure that all parties are clear on the requirements prior to project activation.' : <div dangerouslySetInnerHTML={{
        __html: projectInstructionsHtml
      }}></div>}
      </S.Section>

      {scopes && scopes.length > 0 && <ScopeList scopes={scopes} />}
    </S.DescriptionContainer>;
};
export default ProjectServiceDescription;