import React, { useState } from 'react';
import { Icon } from './styles';
import { StarFilled } from '@ant-design/icons';
const StarIcon = ({
  checked,
  chatPopup,
  clickAction
}) => {
  const [checkedState, setCheckedState] = useState(!!checked);
  return <Icon page={chatPopup} checked={checkedState}>
      <StarFilled onClick={() => {
      setCheckedState(!checkedState);
      clickAction();
    }} data-testid="star-icon" />
    </Icon>;
};
export default StarIcon;