import React from 'react';
import moment from 'moment';
import { Container, Header, Column, Row, Text, ActivatedContainer, Unread } from './styles';
import { RouteRolesEnum } from '@gtintel/platform-types';
const ChatChannelProjectHeader = ({
  conversations,
  project,
  projectName,
  routeRole,
  unreadCount
}) => {
  const name = projectName;
  if (project?.code) name.concat(` - ${project.code}`);
  let clientName = null;
  if (project?.client?.first_name || project?.client?.last_name) {
    clientName = 'Client: ';
    if (project.request_anonymity && routeRole === RouteRolesEnum.PARTNER) {
      clientName += 'Anonymous';
    } else {
      const names = [project.client.first_name, project.client.last_name];
      clientName += names.filter(n => n).join(' ');
    }
  }
  return <Container data-testid="ChatChannelProjectHeader">
      <Header>
        <Column>
          <Row>
            <Text bold data-public>
              {name}
              {unreadCount > 0 && <Unread>{unreadCount}</Unread>}
            </Text>
          </Row>
          {clientName && <Column>{clientName}</Column>}
        </Column>
        <Row>
          <ActivatedContainer>
            {project?.status_display && <Text bold>{project?.status_display}</Text>}
            <Text bold color="#414141">
              {moment(conversations[0].lastMessage.dateCreated).fromNow()}
            </Text>
          </ActivatedContainer>
        </Row>
      </Header>
    </Container>;
};
export default ChatChannelProjectHeader;